import React from 'react';

const ThemeMode = ({ fill = 'none', onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3.66562C12.3883 3.66562 12.7031 3.35081 12.7031 2.9625V0.703125C12.7031 0.314812 12.3883 0 12 0C11.6117 0 11.2969 0.314812 11.2969 0.703125V2.9625C11.2969 3.35081 11.6117 3.66562 12 3.66562Z"
        fill={fill}
      />
      <path
        d="M5.11213 6.10654C5.38673 6.38113 5.8319 6.38113 6.10649 6.10654C6.38109 5.83195 6.38109 5.38677 6.10649 5.11218L4.5089 3.51454C4.2343 3.23995 3.78913 3.23995 3.51454 3.51454C3.23995 3.78913 3.23995 4.2343 3.51454 4.5089L5.11213 6.10654Z"
        fill={fill}
      />
      <path
        d="M5.11213 17.8935L3.51454 19.4911C3.23995 19.7657 3.23995 20.2108 3.51454 20.4854C3.78913 20.76 4.2343 20.76 4.5089 20.4854L6.10654 18.8878C6.38113 18.6132 6.38113 18.168 6.10654 17.8934C5.83195 17.6188 5.38673 17.6189 5.11213 17.8935Z"
        fill={fill}
      />
      <path
        d="M12 20.3345C11.6117 20.3345 11.2969 20.6493 11.2969 21.0376V23.297C11.2969 23.6853 11.6117 24.0001 12 24.0001C12.3883 24.0001 12.7031 23.6853 12.7031 23.297V21.0376C12.7031 20.6493 12.3883 20.3345 12 20.3345Z"
        fill={fill}
      />
      <path
        d="M3.66562 12C3.66562 11.6117 3.35081 11.2969 2.9625 11.2969H0.703125C0.314812 11.2969 0 11.6117 0 12C0 12.3883 0.314812 12.7031 0.703125 12.7031H2.9625C3.35081 12.7031 3.66562 12.3883 3.66562 12Z"
        fill={fill}
      />
      <path
        d="M11.9985 6.0249C8.70384 6.0249 6.02344 8.70531 6.02344 11.9999C6.02344 15.2945 8.70384 17.9749 11.9985 17.9749C15.2931 17.9749 17.9735 15.2945 17.9735 11.9999C17.9735 8.70531 15.2931 6.0249 11.9985 6.0249Z"
        fill={fill}
      />
      <path
        d="M18.8878 6.10654L20.4854 4.5089C20.76 4.2343 20.76 3.78913 20.4854 3.51454C20.2109 3.23995 19.7657 3.23995 19.4911 3.51454L17.8934 5.11218C17.6189 5.38677 17.6189 5.83195 17.8934 6.10654C18.168 6.38113 18.6132 6.38113 18.8878 6.10654Z"
        fill={fill}
      />
      <path
        d="M18.8878 17.8934C18.6132 17.6189 18.168 17.6189 17.8934 17.8934C17.6189 18.168 17.6189 18.6132 17.8934 18.8878L19.4911 20.4854C19.7657 20.76 20.2109 20.76 20.4854 20.4854C20.76 20.2109 20.76 19.7657 20.4854 19.4911L18.8878 17.8934Z"
        fill={fill}
      />
      <path
        d="M23.2984 11.2969H21.0391C20.6507 11.2969 20.3359 11.6117 20.3359 12C20.3359 12.3883 20.6507 12.7031 21.0391 12.7031H23.2984C23.6868 12.7031 24.0016 12.3883 24.0016 12C24.0016 11.6117 23.6868 11.2969 23.2984 11.2969Z"
        fill={fill}
      />
    </svg>
  );
};

export default ThemeMode;
